<template>
    <section class="custom-scroll overflow-auto" style="height:calc(100vh - 170px);">
        <div v-if="dataTable.length > 0" class="row mx-0">
            <div class="col-12">
                <tabla-general :data="dataTable" :columnas="dataColumns" :columnas-formateadas="formaterColumns" :activar-seleccion="true" @seleccion="push">
                    <template slot="cabecera-izquierda">
                        <p class="text-muted2 f-18 f-600">Listado de Vendedores Aprobados</p>
                    </template>
                    <template slot="adicionales-izquierda">
                        <el-table-column
                        prop="favorite"
                        label=""
                        width="60"
                        class-name="text-center"
                        >
                            <template slot-scope="{row}">
                                <estrellas :row="row" />
                            </template>
                        </el-table-column>
                    </template>
                    <template slot="adicionales-derecha">
                        <el-table-column align="center" label="Aprobación" class-name="text-center f-16 text-muted2" prop="fecha_aprobada_format" min-width="140" sortable="custom">
                            <template slot-scope="scope">
                                {{ scope.row.fecha_aprobada_format }}
                            </template>
                        </el-table-column>
                        <el-table-column
                        label="Cedis"
                        width="180"
                        class-name="f-16 text-muted2 text-left"
                        >
                            <template slot-scope="{row}">
                                <p>
                                    {{ row.nombre_cedis ? row.nombre_cedis : 'No registra' }}
                                </p>
                            </template>
                        </el-table-column>
                    </template>
                </tabla-general>
            </div>
        </div>
        <div v-else class="row justify-center h-100 align-items-center">
            <div class="col-3 text-center">
                <div class="border d-middle-center br-12 mx-auto" style="width:162px;height:162px;">
                    <img src="/img/no-imagen/shop.svg" alt="" class="text-center" />
                </div>
                <p class="my-2 text-muted2 f-15">
                    No has aprobado ningúna tienda
                </p>
            </div>
        </div>
    </section>
</template>

<script>
import aprobados from '~/services/tiendas/aprobadas'
import { mapGetters } from 'vuex'

export default {
    data(){
        return{
            dataTable: [],
            dataColumns: [
                {
                    valor: 'propietario_nombre',
                    titulo: 'Vendedor',
                    class: "f-16 text-center text-muted2"
                },
                {
                    valor: 'ciudad',
                    titulo: 'Ciudad',
                    class: "f-16 text-center text-muted2"
                },
                {
                    valor: 'fecha_postulacion',
                    titulo: 'Postulacion',
                    class: "f-16 text-center text-muted2"
                },
                'fecha_aprobada_format'
            ],
            formaterColumns: {
                fecha_aprobada_format: { valor: 'fecha_aprobada', fecha: true },
            },
        }
    },
    computed: {
        ...mapGetters({
            id_cedis:'cedis/id_cedis',
        })
    },
    watch: {
        async id_cedis(){
            if (this.id_cedis != null){
                this.listarTiendas()
            }
        }
    },
    mounted(){
        if (this.id_cedis != null){
            this.listarTiendas()
        }
    },
    methods: {
        async listarTiendas(){
            try {
                let params =  { id_cedis: this.id_cedis }
                const {data} = await aprobados.listarTiendasAprobadas(params)
                this.dataTable = data.tiendas
            } catch (e){
                this.error_catch(e)
            }
        },
        push(row){
            const ruta = this.$usuario.rol === 1 ? 'admin.tiendas.aprobados.informacion' : 'admin-vip.tiendas.aprobados.informacion'
            this.$router.push({ name: ruta, params :{ id_tienda: row.id } })
        },
    }
}
</script>

<style lang="css" scoped>
    .br-12{
        border-radius: 12px;
    }
</style>
