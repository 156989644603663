<template>
    <section class="pt-4 px-3 overflow-auto custom-scroll" style="height:calc(100vh - 180px);">
        <p class="f-22 text-general f-600 px-3">
            Códigos identificadores
        </p>
        <ValidationObserver
        v-if="$can('botones_leecheros_identificadores')"
        ref="validIdentificador"
        tag="div"
        class="row mx-0 px-3 bg-white br-12 py-3 mt-3"
        >
            <ValidationProvider v-slot="{errors}" rules="required|min:4|max:20" name="identificador" tag="div" class="col px-2">
                <div class="row mx-0">
                    <div class="col-5 px-0">
                        <p class="f-400 f-14 pl-3 text-general">
                            Identificador
                        </p>
                        <el-input v-model="identificador" class="w-100 br-6" size="small" maxlength="21" />
                    </div>
                    <div class="col-auto px-2 d-flex align-items-end">
                        <el-tooltip v-if="$can('botones_leecheros_identificadores')" class="item" effect="light" content="Crear identificador" placement="bottom">
                            <div class="btn-action bg-general cr-pointer d-middle-center br-8">
                                <i class="icon-plus f-17 text-white" @click="agregarIdentificador" />
                            </div>
                        </el-tooltip>
                    </div>
                    <p class="text-danger w-100 pl-3 f-11"> {{ errors[0] }} </p>
                </div>
            </ValidationProvider>
            <div class="col-12 px-0">
                <itemIdentificador v-for="info in lista" :key="info.id" :info="info" @eliminar="listarIdentificadores" @editar="recargaVirtual" />
            </div>
        </ValidationObserver>
    </section>
</template>

<script>
import Produccion from "~/services/tiendas/produccion";

export default {
    components:{
        itemIdentificador: ()=> import('../components/itemIdentificador')
    },
    data(){
        return{
            id_tienda: parseInt(this.$route.params.id_tienda),
            identificador: null,
            lista: []
        }
    },

    mounted(){
        this.listarIdentificadores()
    },
    methods: {
        async listarIdentificadores(){
            try {
                const { data } = await Produccion.consultarIdentificadores(this.id_tienda)
                this.lista = data.identificadores
            } catch (error){
                this.error_catch(error)
            }
        },
        async agregarIdentificador(){
            try {
                const valid = await this.$refs.validIdentificador.validate()
                if(!valid){
                    this.notificacion('Mensaje', 'campos obligatorios', 'warning')
                    return false
                }
                const enviar = {
                    id_tienda: this.id_tienda,
                    identificador: this.identificador
                }
                const {data} = await Produccion.agregar_identificador(enviar)
                const newData = data.identificador
                this.listarIdentificadores()
                this.identificador = null
                this.$refs.validIdentificador.reset()
                this.notificacion('Mensaje', 'Identificador agregado', 'success')

            } catch (e){
                this.error_catch(e)
            }

        },
        recargaVirtual(newItem){
            const newData = { ...this.lista, ...newItem}
        }

    }
}
</script>

<style lang="css" >
.br-12{
    border-radius: 12px;
}
</style>
