<template>
    <div style="height:calc(100vh - 74px);">
        <tabs :tabs="tabs" class="border-bottom mb-3" />
        <router-view />
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    middleware:['auth'],

    data(){
        return {
            tabsReales: {
                admin: [
                    { titulo: 'Producción', ruta: 'admin.tiendas.produccion', num: 0, can:'tab1_leecheros_produccion' },
                    { titulo: 'Postulaciones', ruta: 'admin.tiendas.postulaciones', num: 0, can:'tab1_leecheros_postulaciones'  },
                    // { titulo: 'Aprobados', ruta: 'admin.tiendas.aprobados', num: 0 },
                    { titulo: 'Cambio de datos', ruta: 'admin.tiendas.cambio-datos', num: 0, can:'tab1_leecheros_cambio_datos'  },
                    { titulo: 'Inactivos', ruta: 'admin.tiendas.inactivos', num: 0, can:'tab1_leecheros_inactivos'  },
                    { titulo: 'Denegados', ruta: 'admin.tiendas.denegados', num: 0, can:'tab1_leecheros_denegados'  },
                ],
                'admin-vip': [
                    { titulo: 'Producción', ruta: 'admin-vip.tiendas.produccion', num: 0 },
                    { titulo: 'Postulaciones', ruta: 'admin-vip.tiendas.postulaciones', num: 0 },
                    { titulo: 'Aprobados', ruta: 'admin-vip.tiendas.aprobados', num: 0 },
                    { titulo: 'Inactivos', ruta: 'admin-vip.tiendas.inactivos', num: 0 },
                    { titulo: 'Denegados', ruta: 'admin-vip.tiendas.denegados', num: 0 },
                ]
            }
        }
    },
    computed: {
        ...mapGetters({
            id_cedis:'cedis/id_cedis',
            tabs_contadores:'tiendas/tabs/tabs',
        }),
        tabs(){
            let tabs =  this.tabsReales[this.$usuario.rol_nombre]
            console.log('guardián: ',this.$can('crear.producto'))
            if (this.$usuario.rol_nombre == 'admin'){
                tabs[0].num = this.tabs_contadores.producciones
                tabs[1].num = this.tabs_contadores.postulaciones
                // tabs[2].num = this.tabs_contadores.aprobados
                tabs[2].num = this.tabs_contadores.cambio_datos
                tabs[3].num = this.tabs_contadores.inactivos
                tabs[4].num = this.tabs_contadores.denegados
            } else {
                tabs[0].num = this.tabs_contadores.producciones
                tabs[1].num = this.tabs_contadores.postulaciones
                tabs[2].num = this.tabs_contadores.aprobados
                tabs[3].num = this.tabs_contadores.inactivos
                tabs[4].num = this.tabs_contadores.denegados
            }
            return tabs
        }
    },
    watch: {
        '$route.name'(){
            this.$store.dispatch('tiendas/tabs/action_tabs',this.id_cedis)
        },
        async id_cedis(val){
            if (val != null){
                await this.$store.dispatch('tiendas/tabs/action_tabs',val)
            }
        }
    },
    async mounted(){
        if (this.id_cedis != null){
            await this.$store.dispatch('tiendas/tabs/action_tabs',this.id_cedis)
        }
    }
}
</script>
<style lang="css" scoped>
.br-t-12{
    border-radius: 12px 12px 0px 0px
}
</style>
