<template>
    <section class="px-3" :class="$route.name == 'admin.tiendas.produccion.creditos.tenderoVer' ? 'custom-scroll overflow-auto height-calc' : ''">
        <!-- <div v-if="$route.name != 'admin.tiendas.produccion.creditos'" class="row mx-0 my-3 px-3 align-items-center">
            <i class="icon-left-circle f-22 text-gr-general cr-pointer" @click="$router.go(-1)" />
            <img class="img-tendero mx-2" :src="tienda.logo_mini" alt="" />
            <p class="f-500 text-general f-18">{{ tienda.nombre }}</p>
            <i v-if="$can('boton_tesoreria_leecheros_chat')" class="icon-message-text-outline text-gr-general f-22 ml-auto cr-pointer" @click="AbrirChat" />
        </div> -->
        <div class="row mx-0">
            <div class="col text-general f-500">
                {{ tipo? 'Pagos del':'Créditos dados al' }}  {{ $config.vendedor }} durante este año
            </div>
        </div>
        <div class="row mx-0 justify-content-center my-3">
            <el-switch
            v-model="tipo"
            v-loading="loadingCalendario"
            class="text-center"
            style="display: block"
            active-color="#000000"
            active-text="Pagos"
            inactive-text="Cŕeditos"
            @change="cambioTipo"
            />
        </div>
        <div class="row mx-0">
            <div class="col">
                <echart v-loading="loadingCalendario" :options="calendario" />
            </div>
        </div>
        <div class="row mx-0">
            <div class="col text-general f-500">
                Balance del crédito
            </div>
        </div>
        <div class="row mx-0 my-2 px-2 align-items-center">
            <div class="col">
                <echart v-loading="loadingGraficaBalance" :options="chartBar" />
            </div>
            <div class="">
                <div class="bg-light-f5 shadow br-10 mb-3 text-center p-2 text-white" style="width:150px;">
                    <p class="text-general f-15">Deuda total</p>
                    <p class="text-general f-600 f-18">{{ separadorNumero(deuda.total) }}</p>
                </div>
                <div class="bg-light-f5 shadow br-10 mb-3 text-center p-2 text-white" style="width:150px;">
                    <p class="text-general f-15">Deuda vencida</p>
                    <p class="text-general f-600 f-18">{{ separadorNumero(deuda.vencida) }}</p>
                </div>
            </div>
        </div>
        <div class="row mx-0">
            <div class="col-12">
                <tabla-general v-loading="loadingTabla" :data="tableData" :columnas="dataColumnas" sin-datos="No tienes cuotas" @filaFuncion="abrirModal">
                    <template slot="adicionales-izquierda">
                        <el-table-column prop="id_pedido_admin" label="Pedido" sortable class-name="text-general f-18 text-center">
                            <template slot-scope="scope">
                                {{ scope.row.id_pedido_admin }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="valor" label="Valor del pedido" sortable class-name="text-center text-general f-18">
                            <template slot-scope="scope">
                                {{ separadorNumero(scope.row.valor) }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="fecha_cuota" label="Fecha del pedido" sortable class-name="text-center text-general f-18">
                            <template slot-scope="scope">
                                <p>{{ formatearFecha(scope.row.created_at_pedido,'DD MMM Y') }}</p>
                            </template>
                        </el-table-column>
                    </template>
                    <template v-slot:adicionales-derecha>
                        <el-table-column prop="valor" label="Cuota" sortable class-name="text-center text-general f-18">
                            <template slot-scope="scope">
                                {{ separadorNumero(scope.row.valor_pendiente) }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="fecha_cuota" label="Fecha Cuota" sortable class-name="text-general f-18">
                            <template slot-scope="scope">
                                <p :class="scope.row.vencido?'text-danger-fecha': ''">{{ formatearFecha(scope.row.fecha,'DD MMM Y') }}</p>
                            </template>
                        </el-table-column>
                        <el-table-column>
                            <template v-if="$can('boton_tesoreria_leecheros_registrar_pago')" slot-scope="scope">
                                <div type="button" class="text-center py-1 f-15 text-white bg-general3 br-10 border-0" @click="AbrirResgistrarPago(scope.row)">
                                    Registrar pago
                                </div>
                            </template>
                        </el-table-column>
                    </template>
                </tabla-general>
            </div>
        </div>
        <modal-anular-credito ref="modalAnular" />
        <modalRegistrarPagos ref="RegistrarPago" @registro="registro_nuevo_pago" @seanulo="se_anulo_credito" />
        <modalChat ref="modalChat" />
    </section>
</template>

<script>
import Creditos from '~/services/almacen/creditos'
import {mapGetters} from 'vuex'

import Vue from 'vue'
import echarts from 'echarts'
Vue.component('echarts',echarts);
import moment from 'moment'
let colorGeneral = getComputedStyle(document.documentElement).getPropertyValue('--color-general')
let colorGeneral2 = getComputedStyle(document.documentElement).getPropertyValue('--color-general2')

export default {
    components:{
        modalAnularCredito: () => import('./partials/modalAnularCredito'),
        modalRegistrarPagos: () => import('./partials/modalRegistrarPago'),

    },
    data(){
        return {
            loadingTabla: false,
            loadingGraficaBalance: false,
            loadingCalendario: false,
            tipo: false,
            id_tienda: parseInt(this.$route.params.id_tienda),
            tienda:{
                nombre: '',
                logo_mini: null,
                created_by: null
            },
            deuda:{
                total: 0,
                vencida: 0
            },
            dataColumnas: [
                'id_pedido_admin','valor_final','valor_pendiente','fecha','created_at_pedido'
            ],
            tableData: [
                // { tendero: 'OXXO', deuda: '$ 534.000', ultimo_pago: '01 Jul. 2018', ultimo_pedido: '01 Jul. 2018', proximo_pago: '05 Jul. 2008' },
            ],
            cuotas_anual: [],
            pagos_anual: [],
            chartBar: {
                tooltip: {
                    trigger: 'axis',
                    formatter: params => {
                        const valor = this.separadorNumero(params[0].data)
                        return  params[0].axisValue +' - '+valor


                    },
                    axisPointer: {
                        type: 'cross',
                        label: {
                            backgroundColor: '#283b56'
                        }
                    }
                },
                grid: {
                    left: 150,
                    top: 25,
                    bottom:30
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: []
                },
                yAxis: {
                    axisLabel: {
                        formatter: (value) => `${this.moneda.sigla}${this.separadorNumero(value,0,false)}`,
                    }
                },
                series: [{
                    data: [],
                    type: 'line',
                    areaStyle: {
                        color: new echarts.graphic.LinearGradient(
                            0, 0, 0, 1,
                            [
                                {offset: 0.2, color: colorGeneral2 },
                                {offset: 1, color: '#F8F8F8' },
                            ]
                        )
                    },
                    itemStyle: {
                        color: colorGeneral
                    },
                }]
            },
            calendario: {
                title: {
                    text: '',
                    left: 'center',
                    textStyle: {
                        color : '#000000',
                        fontWeight: 'bold'
                    }
                },
                tooltip: {
                    position: 'top'
                },
                visualMap: {
                    min: 0,
                    max: 1000000,
                    calculable: true,
                    orient: 'horizontal',
                    left: 'center',
                    top: 'bottom',
                    inRange: {
                        color: ['#FF9D32','#FF2525']
                    },
                },
                calendar: [{
                    range: [
                        moment().startOf('month').subtract(9, 'months').format('YYYY-MM-DD'),
                        moment().startOf('month').add(2, 'months').endOf('month').format('YYYY-MM-DD')],//['2020-01-01', '2021-01-01'], // necesita fechas por defecto
                    // range: ['2020-03-01', '2021-02-28'], // necesita fechas por defecto
                    yearLabel: {
                        show: false
                    },
                    monthLabel: {nameMap: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic']},
                    dayLabel: {nameMap: ['D', 'L', 'M', 'M', 'J', 'V', 'S'], margin: 13},
                    cellSize: ['auto', 20]
                }],

                series: [{
                    type: 'heatmap',
                    coordinateSystem: 'calendar',
                    calendarIndex: 0,
                    data: [],
                    tooltip: {
                        formatter: param => {
                            const data = param.data
                            return [
                                `<span style="color:${param.color};"></span> ${moment(data.value[0]).format('DD MMM YYYY')} <br/> ${this.separadorNumero(data.value[1])}<br/> `
                            ].join('')
                        }
                    }
                }]
            },
        }
    },
    computed:{
        ...mapGetters({
            id_cedis:'cedis/id_cedis',
            id_moneda:'cedis/id_moneda',
            moneda:'cedis/moneda',
        }),
    },
    watch:{
        id_cedis(val, oldVal){
            if(oldVal == null) return
            this.graficaBalanceCredito()
        },
        id_moneda(val, oldVal){
            if(oldVal == null) return
            this.graficaBalanceCredito()
        }
    },
    mounted(){
        this.montar()
    },
    methods: {
        async montar(){
            try {
                if(this.id_cedis === null || this.id_moneda === null){
                    setTimeout(async() => {
                        await this.listarTabla()
                        await this.graficaBalanceCredito()
                        await this.graficaCalendario()
                    }, 1000);
                }else{
                    await this.listarTabla()
                    await this.graficaBalanceCredito()
                    await this.graficaCalendario()
                }
            } catch (error){
                console.log(error)
            }
        },
        anular(){
            this.$refs.modalAnular.toggle()
        },
        AbrirChat(){
            this.$refs.modalChat.toggle(this.tienda.created_by, 6) // 6 = tipo credito pedido -admin
        },
        registro_nuevo_pago(newPago){
            this.pagos_anual.push(newPago)
            if(this.tipo) this.cambioTipo() // para que recargue si esta en el calendario de pagos
            this.listarTabla()
        },
        se_anulo_credito(){
            this.listarTabla()
        },
        AbrirResgistrarPago(row){
            this.$router.push({name: 'admin.tesoreria.creditos.cobro.ver', params: { id_pedido: row.id_pedido_admin }})
            // this.$router.push({name: 'admin.tiendas.produccion.creditos.cobro.ver', params: { id_pedido: row.id_pedido_admin }})

        },
        abrirModal(row){
            if (!this.$can('boton_tesoreria_leecheros_registrar_pago')) return
            this.AbrirResgistrarPago(row)
        },
        cambioTipo(){
            if(this.tipo){
                this.getVirtulData3(this.pagos_anual)
            }else{
                this.getVirtulData3(this.cuotas_anual)
            }

        },
        async listarTabla(){
            try {
                const params = {
                    id_moneda: this.id_moneda,
                    id_tienda: this.id_tienda
                }
                this.loadingTabla = true
                const {data} = await Creditos.verCredito(params)
                this.tienda = data.tienda
                this.tableData = data.cuotas
                this.loadingTabla = false
            } catch (e){
                this.loadingTabla = false
                this.error_catch(e)
            }
        },
        async graficaBalanceCredito(){
            try {
                const params = {
                    id_cedis: this.id_cedis,
                    id_moneda: this.id_moneda,
                    id_tienda: this.id_tienda
                }
                this.loadingGraficaBalance = true
                const {data} = await Creditos.graficaBalanceCredito(params)
                this.loadingGraficaBalance = false
                const EJE_X = data.grafica.map(e => e.dia)
                const EJE_y = data.grafica.map(e => e.value)
                this.chartBar.series[0].data = EJE_y
                this.chartBar.xAxis.data = EJE_X
                this.deuda = data.deuda

            } catch (e){
                this.loadingGraficaBalance = false
                this.error_catch(e)
            }
        },
        async graficaCalendario(){
            try {
                this.loadingCalendario = true
                const params = {
                    id_moneda: this.id_moneda,
                    id_tienda: this.id_tienda
                }
                const {data} = await Creditos.graficaCalendarioCredito(params)
                this.loadingCalendario = false
                this.cuotas_anual = data.cuotas
                this.pagos_anual = data.pagos
                this.getVirtulData3(this.cuotas_anual)

            } catch (e){
                this.loadingCalendario = false
                this.error_catch(e)
            }
        },
        getVirtulData3(info){


            this.loadingGraficaBalance= true
            this.calendario.visualMap.inRange.color = this.tipo ? ['#ededed','#03D6BC']:['#FF9D32','#FF2525']
            const registros = info
            let hace_9_meses = moment().startOf('month').subtract(9, 'months').format('YYYY-MM-DD')
            let en_2_meses = moment().startOf('month').add(2, 'months').endOf('month').format('YYYY-MM-DD')
            var date = +echarts.number.parseDate(hace_9_meses);
            var end = +echarts.number.parseDate(en_2_meses);
            var dayTime = 3600 * 24 * 1000;
            var array = [];
            var cant = 0
            var max_valor = 0
            for (var time = date; time <= end; time += dayTime){
                cant = cant +1
                let acumulados = null
                registros.forEach(el => {
                    var fecha_dinamica = this.tipo ? moment(el.created_at).format('DD/MM/YYYY'): moment(el.fecha).format('DD/MM/YYYY')
                    if (fecha_dinamica=== echarts.format.formatTime('dd/MM/yyyy', time)){
                        acumulados = acumulados + parseFloat(el.valor)
                    }
                })
                if(acumulados > max_valor) max_valor = acumulados
                array.push(
                    {
                        value: [echarts.format.formatTime('yyyy-MM-dd', time), acumulados],
                        titulo: 'letrero'
                    })
            }
            this.calendario.visualMap.max = max_valor
            this.calendario.series[0].data = array
            this.calendario.title.text = `${hace_9_meses} - ${en_2_meses}`;
            this.loadingGraficaBalance= false
            console.log({array, cant, max_valor,});
        }
    }
}
</script>

<style lang="scss" scoped>
.img-tendero{
    width: 35px;
    height: 35px;
    border-radius: 50%;
    object-fit: cover;
}
.text-danger-fecha{
    color: #FF0000;
}
.height-calc{
    height: calc(100vh - 251px);
}
</style>
