<template>
    <section>
        <info-tienda ref="info_tienda" />
        <!-- <div class="row mx-0 "> -->
        <!-- <div class="col-12 px-0">
            </div> -->
        <!-- </div> -->
        <!-- Modales -->
        <!-- <modalAsignarPlanPago ref="AsignarPlanPago" @actualizar="actualizar_datos" /> -->
        <modalUbicarTienda ref="UbicarMapa" />
        <modalJustificacionInactividad ref="JustificacionInactividad" />
        <modalInactivarTienda ref="InactivarTienda" />
        <modalJustificacion ref="Justificacion" />
        <modalPublicarTienda ref="PublicarTienda" />
        <modalActivarTienda ref="ActivarTienda" />
        <modalDetallePostulacion ref="DetallesPostulacion" :detalle="detalle_historial" />
        <modalChat ref="modalChat" soporte :estado="chatActivo" @inactivar="inactChat" />
        <modalActivarInactivar ref="modalActivarInactivar" @estadoChat="estadoChat" />
        <!-- Modales -->
    </section>
</template>

<script>
import { mapGetters } from 'vuex'
import Produccion from "~/services/tiendas/produccion"

export default {
    components: {
        infoTienda: () => import('../components/infoTienda'),
        modalInactivarTienda: () => import('../partials/modalInactivarTienda'),
        // modalAsignarPlanPago: () => import('../partials/modalAsignarPlanPago'),
        modalUbicarTienda: () => import('../partials/modalUbicarTienda'),
        modalJustificacionInactividad: () => import('../partials/modalJustificacionInactividad'),
        modalJustificacion: () => import('../partials/modalJustificacion'),
        modalPublicarTienda: () => import('../partials/modalPublicarTienda'),
        modalActivarTienda: () => import('../partials/modalActivarTienda'),
        modalDetallePostulacion : () => import('../partials/modalDetallesPostulacion'),
        modalActivarInactivar: () => import('../partials/modalActivarInactivar'),
    },
    data(){
        return{
            favorite: false,
            chatActivo: true,
            historial: [],
            detalle_historial: {},
            tab: 1
        }
    },
    computed:{
        ...mapGetters({
            tienda: 'tiendas/info_cabecera/tienda'
        }),
        isIdentificador(){
            return this.$route.name.match('identificadores')
        },
        isEstado(){
            return this.$route.name.match('estado-leechero')
        },
        isParamatro(){
            return this.$route.name.match('parametros')
        },
        isProduccion(){
            return this.$route.name.match('produccion')
        },
        tabNameIdentificadores(){
            return this.isProduccion ? 'admin.tiendas.produccion.informacion.identificadores' : 'admin.tiendas.inactivos.informacion.identificadores'
        },
        tabNameEstado(){
            return this.isProduccion ? 'admin.tiendas.produccion.informacion.estado-leechero' : 'admin.tiendas.inactivos.informacion.estado-leechero'
        },
        tabNameParametro(){
            return this.isProduccion ? 'admin.tiendas.produccion.informacion.parametros' : 'admin.tiendas.inactivos.informacion.parametros'
        },
    },
    mounted(){
        this.historial_estados()
    },
    methods: {
        openChat(){
            this.$refs.modalChat.toggle()
        },
        isActive(){
            return this.$route.name
        },
        actualizar_datos(){
            this.$refs.info_tienda.info_tienda()
            this.historial_estados()
        },
        async historial_estados(){
            try {
                const { data } = await Produccion.historial_estados(this.$route.params.id_tienda)
                this.historial = data.historial
            } catch (error){
                this.error_catch(error)
            }
        },
        inactChat(){
            this.openChat()
            this.$refs.modalActivarInactivar.toggle(this.chatActivo)
        },
        estadoChat(est){
            if (est){
                this.chatActivo = true
            }else{
                this.chatActivo = false
            }
        },
        AbrirInactivarTienda(){
            this.$refs.InactivarTienda.toggle();
        },
        AbrirUbicarTienda(){
            this.$refs.UbicarMapa.toggle();
        },
        validacionColores(tipo){
            switch (tipo){
            case 'Plan':
                return '#9508EB'
                break;
            case 'Motivo':
                return '#FF2525'
                break;
            case 'Aprobacion':
                return '#0892DD'
                break;
            case 'Denegado':
                return '#FF612B'
                break;
            default:
                break;
            }
        },
        AbrirPublicarTienda(){
            this.$refs.PublicarTienda.toggle(this.tienda);
        },
        AbrirActivarTienda(){
            this.$refs.ActivarTienda.toggle();
        },
        AbrirDetallesPostulacion(item){
            this.detalle_historial = item
            this.$refs.DetallesPostulacion.toggle();
        },
    }
}
</script>

<style lang="scss" scoped>
.br-12{ border-radius: 12px; }
a{
    text-decoration: none !important; 
    &:hover{
        color: initial !important;
    }
}
</style>
