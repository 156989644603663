import axios from 'axios'

const API = 'aprobadas'

const APROBADAS = {

    listarTiendasAprobadas(params){
        return axios(`${API}/lista`, { params })
    },



}

export default APROBADAS
