<template>
    <section class="pt-2">
        <div class="row mx-0">
            <div v-if="dataTable.length" class="col-12">
                <tabla-general class-header="text-general" :data="dataTable" alto="calc(100vh - 318px)">
                    <template slot="cabecera-izquierda">
                        <div class="col-auto">
                            <p class="text-general f-18 f-600">Calificaciones de los pedidos de la tienda</p>
                        </div>
                        <div class="col d-flex">
                            <div class="col-auto ml-auto">
                                <p class="text-general f-600 f-15 cr-pointer" @click="AbrirCalificacionesGenerales()">
                                    {{ info_calificacion.calificacion }}
                                    <i class="icon-star f-18" style="color:#FF9D32;" />
                                </p>
                            </div>
                            <div class="col-auto">
                                <p class=" text-general f-600 f-15">
                                    <strong class="f-18 f-600">
                                        {{ info_calificacion.porcentaje }}%
                                    </strong>
                                    de los pedidos calificados
                                </p>
                            </div>
                        </div>
                    </template>
                    <template slot="adicionales-derecha">
                        <template>
                            <el-table-column prop="id" label="Pedido" class-name="f-16 text-center" />
                            <el-table-column v-if="caso == 2" prop="nombre_tienda" :label="`${$config.vendedor }`" class-name="f-16 text-center" />
                            <el-table-column prop="nombre" :label="`${$config.cliente}`" class-name="f-16 text-center" />
                            <el-table-column
                            label="Valor"
                            class-name="f-16 text-center"
                            >
                                <template slot-scope="{ row }">
                                    <p>
                                        {{ separadorNumero(row.total) }}
                                    </p>
                                </template>
                            </el-table-column>
                            <el-table-column
                            label="Fecha"
                            class-name="f-16 text-center"
                            >
                                <template slot-scope="{ row }">
                                    <p>
                                        {{ row.created_at }}
                                    </p>
                                </template>
                            </el-table-column>
                            <el-table-column
                            label="Calificación"
                            class-name="f-16 text-center"
                            >
                                <template slot-scope="{ row }">
                                    <div class="d-flex justify-center cr-pointer" @click="AbrirCalificacionPedido(row.id)">
                                        <p>
                                            {{ row.calificacion }}
                                        </p>
                                        <i class="icon-star" style="color:#FF9D32;" />
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column
                            v-if="caso == 1"
                            prop="Experiencia"
                            label="Experiencia"
                            class-name="f-16 text-muted2 text-center"
                            >
                                <template v-if="row.satisfaccion !== null" slot-scope="{row}">
                                    <img width="32" height="32" :src=" row.satisfaccion == 33 ? '/img/icons/emotes/Bien.svg' : row.satisfaccion == 50 ? '/img/icons/emotes/MeEncanta.svg' : row.satisfaccion == 15 ? '/img/icons/emotes/Uhh.svg' : '' " alt="" />
                                </template>
                            </el-table-column>
                        </template>
                    </template>
                </tabla-general>
                <!-- Sin Datos -->
            </div>
            <div v-else class="w-100" style="height:calc(100vh - 180px)">
                <sin-datos icon="star" :mensaje="`El ${$config.vendedor} no ha recibido calificación de los pedidos`" />
            </div>
        </div>
        <!-- Modales -->
        <modalCalificacionesGenerales ref="CalificacionesGenerales" />
        <modalCalificacionesPedido ref="CalificacionPedido" />
        <!-- Modales -->
    </section>
</template>

<script>
import { mapGetters } from 'vuex'
import Calificaciones from "../../services/calificaciones";
export default {
    components: {
        modalCalificacionesGenerales: () => import('./partials/modalCalificacionesGenerales'),
        modalCalificacionesPedido: () => import('./partials/modalCalificacionPedido')
    },
    middleware:['auth'],
    data(){
        return{
            dataTable: [],
            info_calificacion: {
                calificacion: 0,
                porcentaje: 0
            }
        }
    },
    computed: {
        ...mapGetters({
            id_cedis:'cedis/id_cedis',
            id_moneda:'cedis/id_moneda',
        }),
        id_tienda(){
            let id_tienda = null
            if (this.$route.params.id_tienda){
                id_tienda = this.$route.params.id_tienda
            } else {
                id_tienda = 0
            }

            return id_tienda
        },
        caso(){
            if (this.$route.name == 'admin.tiendas.produccion.calificaciones'){
                return 1
            } else {
                return 2
            }
        }
    },
    watch: {
        id_moneda(val,oldVal){
            if(oldVal == null) return
            this.get_datos()
        },
        id_cedis(val,oldVal){
            if(oldVal == null) return
            this.get_datos()
        }
    },
    mounted(){
        if(this.id_cedis === null || this.id_moneda === null){
            setTimeout(() => {
                this.get_datos()
            }, 500);
        }else{
            this.get_datos()
        }

    },
    methods: {
        AbrirCalificacionesGenerales(){
            this.$refs.CalificacionesGenerales.toggle()
        },
        AbrirCalificacionPedido(id){
            this.$refs.CalificacionPedido.toggle(id)
        },
        async get_datos(){
            try {
                let params = {
                    caso: this.caso,
                    id_tienda: this.id_tienda,
                    id_moneda: this.id_moneda,
                    id_cedis: this.id_cedis
                }
                const { data } = await Calificaciones.get_datos(params)
                console.log(data);
                this.dataTable = data.data.calificaciones
                this.info_calificacion = data.data.info_calificacion
            } catch (error){
                this.error_catch(error)
            }
        },
    }
}
</script>

<style lang="scss">
.br-12{
    border-radius: 12px;
}
</style>
