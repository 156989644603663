<template>
    <section class="custom-scroll overflow-auto" style="height:calc(100vh - 213px);">
        <mapa-google
        ref="GoogleMaps"
        :desactivar-mapa="false"
        icono-central="/img/icons/tendero-point-map.svg"
        :dragable="false"
        height="100%"
        width="100%"
        @actualizar="getDatos"
        />
    </section>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
    data(){
        return {
            dataTable: [],
            cedis: true,
            tiendas: true,
            clientes: true,
        }
    },
    computed: {
        ...mapGetters({
            id_cedis :'cedis/id_cedis',
            locations: 'produccion/mapa/getLocations',
            refresh: 'produccion/mapa/getRefresh',
        })
    },
    watch: {
        async id_cedis(){
            if (this.id_cedis != null){
                this.setIdCedis(this.id_cedis)
                this.getDatos()
            }
        },
        refresh(val){
            if(val){
                this.addNewMarkers()
            }
        }
    },
    async mounted(){
        this.setIdCedis(this.id_cedis)
        await this.getDatos()
        this.iniciarMapa()
    },
    methods: {
        ...mapActions({
            getDatosMapa: 'produccion/mapa/getDatosMapa',
        }),
        ...mapMutations({
            setCoordenadas: 'produccion/mapa/setCoordenadas',
            setRefresh: 'produccion/mapa/setRefresh',
            setIdCedis: 'produccion/mapa/setIdCedis',
        }),
        async getDatos(coordenadas){
            try {
                this.setCoordenadas(coordenadas)
                await this.getDatosMapa()
                this.addNewMarkers()
            } catch (error){
                this.error_catch(error)
            }
        },
        async iniciarMapa(){
            await this.getDatos()
            this.$refs.GoogleMaps.initMapMarkerClusterer(this.locations)
        },
        addNewMarkers(){
            this.$refs.GoogleMaps.addNewMarkers(this.locations)
            this.setRefresh(false)
        },
    }
}
</script>

<style lang="scss" scoped>
.br-12{
    border-radius: 12px;
}
.br-15{
    border-radius: 15px;
}
</style>
