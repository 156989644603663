<template>
    <section class="pt-3">
        <div class="row mx-0">
            <div class="col-12">
                <tabla-general :data="tableData" :columnas="dataColumns" :usar-paginacion="false" class-header="text-general f-16" alto="calc(100vh - 286px)">
                    <template slot="cabecera-izquierda">
                        <div class="col-auto text-general f-18 f-500">
                            Historial de pedidos de abastecimiento
                        </div>
                    </template>
                    <template slot="adicionales-derecha">
                        <el-table-column
                        label="Calificaciones"
                        class-name="text-center"
                        width="180"
                        >
                            <template slot-scope="scope">
                                <div class="row mx-0 justify-center">
                                    <div class="col-auto px-0">
                                        3.5
                                    </div>
                                    <div class="col-auto px-0">
                                        <i class="icon-star text-warning f-20" />
                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column
                        label="ID pedido cliente"
                        class-name="text-center"
                        width="180"
                        >
                            <template slot-scope="scope">
                                <div class="row mx-0 justify-center">
                                    <div class="col-auto px-0">
                                        654986
                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column
                        label="Entrega directa"
                        class-name="text-center"
                        width="180"
                        >
                            <template slot-scope="scope">
                                <div class="row mx-0 justify-center">
                                    <div class="col-auto px-0">
                                        Si
                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column
                        label="Distancia (m)"
                        class-name="text-center"
                        width="180"
                        >
                            <template slot-scope="scope">
                                <div class="row mx-0 justify-center">
                                    <div class="col-auto px-0">
                                        1.000
                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column
                        label="Distancia (m)"
                        class-name="text-center"
                        width="180"
                        >
                            <template slot-scope="scope">
                                <div class="row mx-0 justify-center">
                                    <div class="col-auto px-0">
                                        <i class="icon-web text-general f-17" />
                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                    </template>
                </tabla-general>
            </div>
            <div class="col-12">
                <div class="d-middle-center py-3 br-bt-12 bg-white">
                    <div class="bg-general3 text-white br-20 px-3">
                        Ver más clientes
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data(){
        return {
            tableData: [
                { 
                    id_pedido: '654854', 
                    entrega: '01 Jul. 2020', 
                    hora_entrega: '10:16 a.m', 
                    estado: 'Entregado', 
                    pago_pedido: 'Contado', 
                    productos: 100, 
                    valor_productos: '$ 587.000',
                    impuestos: '$ 5.870',
                    valor_envio: '$ 5.000',
                    descuento: '$ 1.650',
                    valor_cupon: '$ 5.870',
                    cupon: 'Prima',
                    valor_total: '$ 587.000',
                    valor_comision: '$ 1.650',
                    comision: 'Primer nivel',
                    estado_pago: 'No aplica'
                },
                { 
                    id_pedido: '654854', 
                    entrega: '01 Jul. 2020', 
                    hora_entrega: '10:16 a.m', 
                    estado: 'Entregado', 
                    pago_pedido: 'Contado', 
                    productos: 100, 
                    valor_productos: '$ 587.000',
                    impuestos: '$ 5.870',
                    valor_envio: '$ 5.000',
                    descuento: '$ 1.650',
                    valor_cupon: '$ 5.870',
                    cupon: 'Prima',
                    valor_total: '$ 587.000',
                    valor_comision: '$ 1.650',
                    comision: 'Primer nivel',
                    estado_pago: 'No aplica'
                },
                { 
                    id_pedido: '654854', 
                    entrega: '01 Jul. 2020', 
                    hora_entrega: '10:16 a.m', 
                    estado: 'Entregado', 
                    pago_pedido: 'Contado', 
                    productos: 100, 
                    valor_productos: '$ 587.000',
                    impuestos: '$ 5.870',
                    valor_envio: '$ 5.000',
                    descuento: '$ 1.650',
                    valor_cupon: '$ 5.870',
                    cupon: 'Prima',
                    valor_total: '$ 587.000',
                    valor_comision: '$ 1.650',
                    comision: 'Primer nivel',
                    estado_pago: 'No aplica'
                },
            ],
            dataColumns: [
                { valor: 'id_pedido', titulo:"ID pedido" },
                { valor: 'entrega', titulo:"Entrega" },
                { valor: 'hora_entrega', titulo:"Hora Entrega" },
                { valor: 'estado', titulo:"Estado" },
                { valor: 'pago_pedido', titulo:"Pago Pedido" },
                { valor: 'productos', titulo:"Productos" },
                { valor: 'valor_productos', titulo:"Valor productos" },
                { valor: 'impuestos', titulo:"Impuestos" },
                { valor: 'valor_envio', titulo:"Valor envío" },
                { valor: 'descuento', titulo:"Descuento" },
                { valor: 'valor_cupon', titulo:"Valor cupón" },
                { valor: 'cupon', titulo:"Cupón" },
                { valor: 'valor_total', titulo:"Valor total" },
                { valor: 'valor_comision', titulo:"Valor comisión" },
                { valor: 'comision', titulo:"Comisión" },
                { valor: 'estado_pago', titulo:"Estado de pago comisión", ancho: 250, class: 'text-center' }
            ]
        }
    }
}
</script>