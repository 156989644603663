import axios from "axios";

const API = "metricas-leechero-admin";

const metricasAdmin = {
    ventas_mensuales(params){
        return axios(`${API}/ventas-mensuales`, { params });
    },
    ventas_diarias(params){
        return axios(`${API}/ventas-diarias`, { params });
    },
    calificaciones_mensuales(params){
        return axios(`${API}/calificaciones-mensuales`, { params });
    },
    ver_mas_productos(params){
        return axios(`${API}/ver-mas-productos`, { params });
    },
    metricas_informe(params){
        return axios(`${API}/metricas-informe`, { params });
    },
    inventarioLeechero(params){
        return axios(`${API}/inventario-leechero`, { params });
    },
    comparativos(params){
        return axios(`${API}/comparativos`, { params });
    },
};

export default metricasAdmin;
