<template>
    <section>
        <div class="row mx-3 py-2 mb-3 align-items-center">
            <div class="col px-0 border-bottom">
                <tabs :tabs="rutas" class="align-items-center" />
            </div>
            <div class="btn-general f-14 px-3">
                <i v-if="$can('boton_tesoreria_leecheros_chat')" class="icon-message-text-outline text-gr-general f-17 ml-auto cr-pointer" @click="AbrirChat" />
                <span class="ml-1">
                    Chat del crédito
                </span>
            </div>
        </div>
        <router-view />
        <!-- Partials -->
        <modalChat ref="modalChat" />
    </section>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    data(){
        return {
            rutas: [
                {
                    titulo: 'Dashboard',
                    ruta: 'admin.tiendas.produccion.creditos.tenderoVer',
                },
                {
                    titulo: 'Entregas',
                    ruta: 'admin.tiendas.produccion.creditos.entregas',
                    can: 'tab3_leecheros_creditos_entregas'
                },
                {
                    titulo: 'Créditos',
                    ruta: 'admin.tiendas.produccion.creditos.creditos',
                    can: 'tab3_leecheros_creditos_creditos'
                },
                {
                    titulo: 'Historial',
                    ruta: 'admin.tiendas.produccion.creditos.historial',
                    can: 'tab3_leecheros_creditos_historial'
                }
            ]
        }
    },
    computed: {
        ...mapGetters({
            tienda: 'tiendas/info_cabecera/tienda'
        }),
    },
    methods: {
        AbrirChat(){
            this.$refs.modalChat.toggle(this.tienda.created_by, 6) // 6 = tipo credito pedido -admin
        },
    }
}
</script>

<style>

</style>
