var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"pt-3"},[_c('div',{staticClass:"row mx-0"},[_c('div',{staticClass:"col-12 px-0"},[_c('tabla-general',{attrs:{"data":_vm.dataTable,"columnas":_vm.dataColumns,"class-header":"text-general f-16","activar-seleccion":true,"alto":"calc(100vh - 310px)"},on:{"seleccion":_vm.push}},[_c('template',{slot:"cabecera-izquierda"},[_c('p',{staticClass:"text-general pl-3 f-18 f-600"},[_vm._v("Listado de "+_vm._s(_vm.$config.vendedor)+" Inactivos")])]),_c('template',{slot:"adicionales-izquierda"},[_c('el-table-column',{attrs:{"prop":"favorite","width":"60","class-name":"text-center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('estrellas',{attrs:{"row":row}})]}}])})],1),_c('template',{slot:"adicionales-derecha"},[_c('el-table-column',{attrs:{"label":"Motivo de inactividad","width":"200","class-name":"f-16 text-center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-center text-general"},[_vm._v(" "+_vm._s(row.estado_motivo ? row.estado_motivo.nombre : 'No registra')+" ")])]}}])}),_c('el-table-column',{attrs:{"label":"Fecha","width":"100","class-name":"f-16 text-center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-center text-general f-14"},[_vm._v(" "+_vm._s(row.estado_motivo ? row.estado_motivo.fecha : 'No registra')+" ")])]}}])}),_c('el-table-column',{attrs:{"label":"Cedis","width":"180","class-name":"f-16 text-center text-general"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('p',{staticClass:"text-general"},[_vm._v(" "+_vm._s(row.nombre_cedis ? row.nombre_cedis : 'No registra')+" ")])]}}])})],1)],2)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }