<template>
    <section class="custom-scroll overflow-auto">
        <cargando v-if="loading_excel" />
        <div v-if="dataTable.length" class="row mx-0">
            <div class="col-12 px-0">
                <tabla-general
                :data="dataTable"
                :columnas="dataColumns"
                class-header="text-general f-500"
                :columnas-fecha="formaterColumns"
                :activar-seleccion="true"
                alto="calc(100vh - 291px)"
                @seleccion="push"
                >
                    <template slot="cabecera-izquierda">
                        <div class="col-auto">
                            <p class="text-general f-18 f-600">Lista de {{ $config.vendedor }} actualmente en la aplicación</p>
                        </div>
                    </template>
                    <template slot="cabecera-derecha">
                        <vue-excel-xlsx
                        v-if="produccionExcel.length > 0 && $can('botones_leecheros_exportar_tabla')"
                        class="my-lg-2 boton-excel"
                        :data="produccionExcel"
                        :columns="columnsExport"
                        :filename="'leecheros'"
                        :file-type="'xlsx'"
                        :sheetname="'leecheros'"
                        >
                            <div class="btn-general px-4 f-14 mr-2">
                                Exportar tabla
                            </div>
                        </vue-excel-xlsx>
                    </template>
                    <template slot="cabecera-derecha">
						<div class="btn-general px-4 f-14 mr-2" @click="openModalNuevoVendedor">
                            Crear vendedor
                        </div>
                        <router-link
                        :to="{ name: 'admin.tiendas.produccion.lista' }"
                        active-class="active border-black"
                        class="d-middle-center border-black text-general"
                        style="width:32px;height:32px;border-radius:8px 0px 0px 8px"
                        >
                            <i class="icon-menu f-20 my-n1" />
                        </router-link>
                        <router-link
                        :to="{ name: 'admin.tiendas.produccion.mapa' }"
                        active-class="active border-black"
                        class="d-middle-center border-black text-general "
                        style="width:32px;height:32px;"
                        >
                            <i class="icon-location f-20 my-n1" />
                        </router-link>
                        <router-link
                        :to="{ name: 'admin.tiendas.produccion.geolocalizacion' }"
                        active-class="active border-black"
                        class="d-middle-center border-black text-general mr-2"
                        style="width:32px;height:32px;border-radius:0px 8px 8px 0px"
                        >
                            <i class="icon-route f-20 my-n1" />
                        </router-link>
                    </template>
                    <template slot="adicionales-izquierda">
                        <el-table-column
                        prop="favorite"
                        label=""
                        width="50"
                        class-name="text-center"
                        >
                            <template slot-scope="{row}">
                                <div>
                                    <estrellas :row="row" />
                                </div>
                            </template>
                        </el-table-column>
                    </template>
                    <template slot="adicionales-derecha">
                        <el-table-column
                        label="Descuentos de lista"
                        width="180"
                        class-name="f-16 text-muted2 text-left"
                        >
                            <template slot-scope="{row}">
                                <p v-if="row.descuento_lista">
                                    <img :src="row.descuento_lista_imagen" class="br-5" width="32" height="32" /> {{ row.descuento_lista }}
                                </p>
                                <p v-else>-</p>
                            </template>
                        </el-table-column>
                    </template>
                    <template slot="adicionales-derecha">
                        <el-table-column
                        label="Cedis"
                        width="180"
                        class-name="f-16 text-muted2 text-left"
                        >
                            <template slot-scope="{row}">
                                <p>
                                    {{ row.nombre_cedis ? row.nombre_cedis : 'No registra' }}
                                </p>
                            </template>
                        </el-table-column>
                    </template>
                </tabla-general>
            </div>
        </div>
        <div v-else class="row mx-0 h-100 align-items-center justify-center">
            <div class="col-3 text-center">
                <div class="row mx-0 justify-center">
                    <div class="border d-middle-center br-12 mx-5 px-4" style="width:162px;height:162px;">
                        <img src="/img/no-imagen/shop.svg" alt="" />
                    </div>
                </div>
                <p class="my-2 text-muted2 f-15">
                    En el momento no hay {{ $config.vendedor }}s publicadas en la aplicación.
                </p>
            </div>
        </div>
		<modal-nuevo-tendero ref="modalCrearVendedor" @actualizar="get_datos" />
    </section>
</template>

<script>
import { mapGetters } from 'vuex'
import Produccion from "../../../../services/tiendas/produccion";
import Vue from 'vue'

import VueExcelXlsx from "vue-excel-xlsx";
import excel from 'vue-excel-export'
Vue.use(excel)
Vue.use(VueExcelXlsx)

export default {
	components : {
		modalNuevoTendero: () => import('../partials/modalCrearVendedor'),
	},
    data(){
        return {
            loading_excel: false,
            dataTable: [],
            dataColumns: [
                {
                    valor: 'propietario_nombre',
                    titulo: 'Vendedor',
                    class: "f-16 text-left text-muted2"
                },
                {
                    valor: 'fecha_format',
                    titulo: 'Publicación',
                    // ordenar: false,
                    class: "f-16 text-left text-muted2"
                },
                {
                    valor: 'cantidad_clientes',
                    titulo: 'Clientes',
                    class: "f-16 text-left text-muted2",
                    ancho: 200
                },
                {
                    valor: 'cupo_credito',
                    titulo: 'Cupo del crédito',
                    class: "f-16 text-left text-muted2",
                },
                {
                    valor: 'ciudad',
                    titulo: 'Ciudad',
                    class: "f-16 text-left text-muted2"
                },
                {
                    valor: 'telefono',
                    titulo: 'Teléfono',
                    // ordenar: false,
                    class: "f-16 text-left text-muted2"
                },
                {
                    valor: 'entrega_fecha_format',
                    titulo: 'Fecha últ.pedido',
                    class: "f-16 text-left text-muted2"
                },

            ],
            formaterColumns: {
                fecha_format: { valor: 'publicacion', fecha: true },
                entrega_fecha_format: {valor: 'entrega_fecha', fecha:true },
                propietario_nombre: { valor: 'propietario_nombre_minuscula',}
            },
            columnsExport : [
                {
                    label: "Vendedor",
                    field: "propietario_nombre",
                },
                {
                    label: "Publicación",
                    field: "fecha_format",
                },
                {
                    label: "Clientes",
                    field: "cantidad_clientes",
                },
                {
                    label: "Cupo del crédito",
                    field: "cupo_credito",
                },
                {
                    label: "Ciudad",
                    field: "ciudad",
                },
                {
                    label: "Teléfono",
                    field: "telefono",
                },
                {
                    label: "Fecha últ.pedido",
                    field: "entrega_fecha_format",
                },
                {
                    label: "Cedis",
                    field: "nombre_cedis",
                }
            ],
        }
    },
    computed: {
        ...mapGetters({
            id_cedis:'cedis/id_cedis',
        }),
        produccionExcel(){
            let data = _.cloneDeep(this.dataTable);
            return data;
        }
    },
    watch: {
        async id_cedis(){
            if (this.id_cedis != null){
                this.get_datos()
            }
        }
    },
    mounted(){
		this.verificarCredito();
        if (this.id_cedis != null){
            this.get_datos()
        }
        this.dataColumns[0].titulo = `${this.$config.vendedor}`
        this.dataColumns[2].titulo = `Cantidad ${this.$config.cliente}`
    },
    methods: {
		openModalNuevoVendedor(){
            this.$refs.modalCrearVendedor.toggle()
        },
        async get_datos(){
            try {
                let params =  { id_cedis: this.id_cedis }
                const { data } = await Produccion.get_datos(params)
                data.tiendas.map(el =>{
                    el.fecha_format = this.formatearFecha(el.publicacion,'D MMM Y')
                    el.entrega_fecha_format = this.formatearFecha(el.entrega_fecha,'D MMM Y')
                    // el.publicacion = this.formatearFecha(el.publicacion,'Y MMM D')
                    el.propietario_nombre_minuscula = el.propietario_nombre.toLowerCase(),
                    el.cupo_credito = el.cupo_credito == 0 ? 'Ilimitado' : this.separadorNumeroDecimales(el.cupo_credito)
                })
                this.dataTable = data.tiendas
            } catch (error){
                this.error_catch(error)
            }
        },
        push(row){
            const ruta = this.$usuario.rol === 1 ? 'admin.tiendas.produccion.informacion' : 'admin-vip.tiendas.produccion.informacion'
            console.log('click', this.$usuario.rol, 'Mi log');
            this.$router.push({ name: ruta, params :{ id_tienda: row.id } })
        },
        startDownload(){
            this.loading_excel = true
        },
        finishDownload(){
            this.loading_excel = false
        },
		verificarCredito(){
			if(`${this.$config.menu.creditos}` == 0){
				this.columnsExport.splice(3, 1)
				this.dataColumns.splice(3, 1)
			}
		}
    }
}
</script>

<style lang="scss" scoped>
.br-12{
    border-radius: 12px;
}
.br-15{
    border-radius: 15px;
}
.active{
    background-color: var(--text-general);
    color: #ffffff !important;
    :hover{
        color: #ffffff !important;
    }
}
.boton-excel {
    border: 0px;
    background: transparent;
}
</style>
