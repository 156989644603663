<template>
    <section class="pt-2">
        <div class="row mx-0" style="min-height: calc(100vh - 285px)">
            <div v-if="dataTable.length" class="col-12">
                <tabla-general alto="calc(100vh - 405px)" :data="dataTable">
                    <template slot="cabecera-izquierda">
                        <p class="text-muted2 f-18 f-600">Lista de pagos realizados</p>
                    </template>
                    <template slot="adicionales-derecha">
                        <template>
                            <el-table-column prop="id" label="ID" class-name="f-16 text-muted2 text-center" width="125" />
                            <el-table-column prop="id_pedido_admin" label="Id Pedido" class-name="f-16 text-muted2 text-left" width="125" />
                            <el-table-column prop="comentario" label="Comentario" class-name="f-16 text-muted2 text-left" width="500" />
                            <el-table-column label="Pago" class-name="f-16 text-muted2 text-center" width="150">
                                <template slot-scope="{ row }">
                                    <p class="text-right">
                                        {{ separadorNumero(row.valor) }}
                                    </p>
                                </template>
                            </el-table-column>
                            <el-table-column label="Fecha Pago" class-name="f-16 text-muted2 text-center" width="150">
                                <template slot-scope="{ row }">
                                    <p class="text-right">
                                        {{ formatearFecha(row.created_at) }}
                                    </p>
                                </template>
                            </el-table-column>
                        </template>
                    </template>
                </tabla-general>
                <!-- Sin Datos -->
            </div>
            <div v-else class="col-12">
                <sin-datos icon="list" mensaje="No hay datos para mostrar." />
            </div>
        </div>
        <!-- Modales -->
    </section>
</template>

<script>
import Creditos from '~/services/almacen/creditos'
export default {
    data(){
        return{
            dataTable: [],
            id_tienda: parseInt(this.$route.params.id_tienda),
        }
    },
    mounted(){
        this.historialPagosLeechero()
    },
    methods: {
        async historialPagosLeechero(){
            try {
                let params = {
                    id_tienda: this.id_tienda,
                }
                const { data } = await Creditos.historialPagosLeechero(params)
                this.dataTable = data.historialPagos
            } catch (error){
                this.error_catch(error)
            }
        },
    }
}
</script>

<style lang="scss">
.br-12{
    border-radius: 12px;
}
</style>
