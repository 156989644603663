import axios from 'axios'

const API = 'admin-tiendas'

const Tiendas = {
    postulaciones(params){
        return axios(`${API}/lista-postulaciones`, { params });
    },
    denegados(params){
        return axios(`${API}/lista-denegados`, { params });
    },
    detalle(id_tienda){
        return axios(`${API}/${id_tienda}/detalle`)
    },
    cambio_estado(model){
        return axios.put(`${API}/cambio_estado`,model)
    },
    publicarTienda(model){
        return axios.put(`${API}/publicar-tienda`,model)
    }

}

export default Tiendas
