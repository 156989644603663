<template>
    <section>
        <div class="row mx-0 px-5 mt-4 mb-3">
            <div class="col-12 text-general pl-3 f-600">
                Créditos otorgados
            </div>
        </div>
        <div class="custom-scroll overflow-auto" style="height:calc(100vh - 358px);">
            <div class="row mx-0 px-2">
                <div v-for="(credito,k) in creditosOtorgados" :key="k" class="col-12 d-middle border bg-white br-5 py-2 mb-2">
                    <i :class=" `icon-${credito.tipo == 32 ? 'ok-circled-outline' : credito.tipo == 31 ? 'creditos' : credito.tipo == 33 ? 'cancel-circled-outline' : ''}  ${credito.tipo == 32 ? 'text-success f-18' : credito.tipo == 31 ? 'text-general f-16' : credito.tipo == 33 ? 'text-danger f-18' : ''}`" />
                    <div class="col-2 text-general f-600">
                        {{ credito.tipo == 32 ? 'Pago realizado' : credito.tipo == 31 ? 'Nuevo Crédito' : 'Crédito anulado' }}
                    </div>
                    <div class="col text-general">
                        <i class="icon-pedidos text-general f-18" />
                        {{ credito.pedido }}
                    </div>
                    <div class="col text-general f-500">
                        {{ calcularSiglaMoneda(credito.idMoneda) }} {{ separadorNumero(credito.valorFinal) }}
                    </div>
                    <div class="col text-general">
                        {{ formatearFecha(credito.created_at) }}
                    </div>
                    <div class="col-3 pr-0 d-middle">
                        <template v-if="credito.tipo == 31">
                            <i class="icon-currency-usd-circle text-general f-18" />
                            <div class="col-5">
                                <span class="text-general">
                                    Valor:
                                </span>
                            </div>
                            <div class="col text-right">
                                <span class="text-general f-600">
                                    {{ calcularSiglaMoneda(credito.idMoneda) }} {{ separadorNumero(credito.valorCredito) }}
                                </span>
                            </div>
                        </template>
                        <template v-else-if="credito.tipo == 33">
                            <i class="icon-currency-usd-circle text-general f-18" />
                            <div class="col-5">
                                <span class="text-general">
                                    Acumulado:
                                </span>
                            </div>
                            <div class="col text-right">
                                <span class="text-general f-600">
                                    {{ calcularSiglaMoneda(credito.idMoneda) }} {{ separadorNumero(credito.valorCredito) }}
                                </span>
                            </div>
                        </template>
                        <template v-else>
                            <i class="icon-currency-usd-circle text-general f-18" />
                            <div class="col-5">
                                <span class="text-general">
                                    Pago:
                                </span>
                            </div>
                            <div class="col text-right">
                                <span class="text-general f-600">
                                    {{ calcularSiglaMoneda(credito.idMoneda) }} {{ separadorNumero(credito.pago) }}
                                </span>
                            </div>
                        </template>
                    </div>
                    <div class="col-3 pr-0 d-flex text-general">
                        <template v-if="credito.tipo == 32">
                            <div class="col px-0">
                                Deuda pendiente:
                            </div>
                            <span class="mx-2">
                                {{ calcularSiglaMoneda(credito.idMoneda) }} {{ separadorNumero(credito.valorCredito) }}
                            </span>
                        </template>
                        <template v-else-if="credito.tipo == 33">
                            <div class="col px-0">
                                Deuda anulada:
                            </div>
                            <span class="mx-2">
                                {{ calcularSiglaMoneda(credito.idMoneda) }} {{ separadorNumero(credito.deuda) }}
                            </span>
                        </template>
                        <template v-else-if="credito.tipo == 31 && credito.pago != null">
                            <div class="col px-0">
                                Anticipo:
                            </div>
                            <span class="mx-2">
                                {{ calcularSiglaMoneda(credito.idMoneda) }} {{ separadorNumero(credito.pago) }}
                            </span>
                        </template>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="row mx-0 my-2 justify-center">
            <div class="col-auto">
                <div class="bg-general3 text-white cr-pointer br-20 px-4 f-14 py-1" @click="historialCreditosLeechero(10)">
                    Ver más 10
                </div>
            </div>
            <div class="col-auto">
                <div class="bg-general3 text-white cr-pointer br-20 px-4 f-14 py-1" @click="historialCreditosLeechero(50)">
                    Ver más 50
                </div>
            </div>
        </div> -->
    </section>
</template>

<script>
import Creditos from '~/services/almacen/creditos'
export default {
    data(){
        return {
            creditosOtorgados: [],
            id_tienda: parseInt(this.$route.params.id_tienda),
        }
    },
    mounted(){
        this.historialCreditosLeechero()
    },
    methods: {
        async historialCreditosLeechero(paginate = 10){
            try {
                const { id: lastId = null } = _.last(this.creditosOtorgados) ?? {}
                const params = {
                    id_tienda: this.id_tienda,
                    paginate,
                    lastId
                }
                this.loadingTabla = true
                const {data} = await Creditos.historialCreditosLeechero(params)
                this.creditosOtorgados = [...this.creditosOtorgados, ...data.creditosPagos.map( el => ( { ...el, ...JSON.parse(el.mensaje) } ) )]
                this.loadingTabla = false
            } catch (e){
                this.loadingTabla = false
                this.error_catch(e)
            }
        },
    }
}
</script>

<style lang="scss" scoped>
</style>
