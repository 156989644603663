<template>
    <section>
        <div class="row mx-0 px-3 pt-3">
            <div class="col-12 bg-white br-8 px-0">
                <tabla-general :data="datos" alto="calc(100vh - 324px)" class-header="text-general f-16 f-500" :usar-paginacion="false" @seleccion="onRowClick($event)">
                    <template slot="cabecera-izquierda">
                        <div class="col-auto text-general f-18 f-500">
                            Historial de pedidos de abastecimiento
                        </div>
                    </template>
                    <template slot="adicionales-izquierda">
                        <el-table-column
                        label="Id Pedido"
                        prop="id"
                        sortable
                        width="130"
                        >
                            <template slot-scope="scope">
                                <div class="row mx-0 justify-center">
                                    <div class="col-auto px-0 text-general f-16">
                                        {{ scope.row.id }}
                                    </div>
                                    <i class="icon-start text-warning f-20" />
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column
                        label="Entrega"
                        prop="entrega_fecha"
                        sortable
                        width="130"
                        >
                            <template slot-scope="scope">
                                <div class="row mx-0 justify-center">
                                    <div class="col-auto px-0 text-general f-16">
                                        {{ formatearFecha(scope.row.entrega_fecha) }}
                                    </div>
                                    <i class="icon-start text-warning f-20" />
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column
                        label="Entrega horario"
                        prop="entrega_horario"
                        sortable
                        width="170"
                        >
                            <template slot-scope="scope">
                                <div class="row mx-0 justify-center">
                                    <div class="col-auto px-0 text-general f-16">
                                        {{ formatTime(scope.row.entrega_fecha,scope.row.entrega_horario) }}
                                    </div>
                                    <i class="icon-start text-warning f-20" />
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column
                        label="Estado"
                        prop="estado"
                        sortable
                        width="120"
                        >
                            <template slot-scope="scope">
                                <div class="row mx-0 align-center">
                                    <div class="col-auto px-0 text-general f-16">
                                        {{ estadoNombre(scope.row.estado) }}
                                    </div>
                                    <i class="icon-start text-warning f-20" />
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column
                        label="Pago estado"
                        prop="pago_estado"
                        sortable
                        width="150"
                        >
                            <template slot-scope="scope">
                                <div class="row mx-0 align-center">
                                    <div class="col-auto px-0 text-general f-16">
                                        {{ pagoEstado(scope.row.pago_estado) }}
                                    </div>
                                    <i class="icon-start text-warning f-20" />
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column
                        label="Productos"
                        prop="cantidad_productos"
                        sortable
                        width="140"
                        >
                            <template slot-scope="scope">
                                <div class="row mx-0 justify-center">
                                    <div class="col-auto px-0 text-general f-16">
                                        {{ scope.row.cantidad_productos }}
                                    </div>
                                    <i class="icon-start text-warning f-20" />
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column
                        label="Valor productos"
                        prop="valor_productos"
                        sortable
                        width="180"
                        >
                            <template slot-scope="scope">
                                <div class="row mx-0 justify-center">
                                    <div class="col-auto px-0 text-general f-16">
                                        {{ convertMoney(scope.row.valor_productos,scope.idm_moneda) }}
                                    </div>
                                    <i class="icon-start text-warning f-20" />
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column
                        label="Impuestos"
                        prop="valor_impuestos"
                        sortable
                        width="180"
                        >
                            <template slot-scope="scope">
                                <div class="row mx-0 justify-center">
                                    <div class="col-auto px-0 text-general f-16">
                                        {{ convertMoney(scope.row.valor_impuestos,scope.idm_moneda) }}
                                    </div>
                                    <i class="icon-start text-warning f-20" />
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column
                        label="Valor envio"
                        prop="valor_envio"
                        sortable
                        width="140"
                        >
                            <template slot-scope="scope">
                                <div class="row mx-0 justify-center">
                                    <div class="col-auto px-0 text-general f-16">
                                        {{ convertMoney(scope.row.valor_envio,scope.idm_moneda) }}
                                    </div>
                                    <i class="icon-start text-warning f-20" />
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column
                        label="Descuento"
                        prop="valor_descuento"
                        sortable
                        width="140"
                        >
                            <template slot-scope="scope">
                                <div class="row mx-0 justify-center">
                                    <div class="col-auto px-0 text-general f-16">
                                        {{ convertMoney(scope.row.valor_descuento,scope.idm_moneda) }}
                                    </div>
                                    <i class="icon-start text-warning f-20" />
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column
                        label="Valor cupón"
                        prop="valor_cupon"
                        sortable
                        width="140"
                        >
                            <template slot-scope="scope">
                                <div class="row mx-0 justify-center">
                                    <div class="col-auto px-0 text-general f-16">
                                        {{ convertMoney(scope.row.valor_cupon,scope.idm_moneda) }}
                                    </div>
                                    <i class="icon-start text-warning f-20" />
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column
                        label="Nombre cupón"
                        prop="nombre_cupon"
                        sortable
                        width="180"
                        >
                            <template slot-scope="scope">
                                <div class="row mx-0 justify-center">
                                    <div class="col-auto px-0 text-general f-16">
                                        {{ scope.row.nombre_cupon || "-" }}
                                    </div>
                                    <i class="icon-start text-warning f-20" />
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column
                        label="Valor total"
                        prop="vaor_total"
                        sortable
                        width="140"
                        >
                            <template slot-scope="scope">
                                <div class="row mx-0 justify-center">
                                    <div class="col-auto px-0 text-general f-16">
                                        {{ convertMoney(scope.row.valor_total,scope.idm_moneda) }}
                                    </div>
                                    <i class="icon-start text-warning f-20" />
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column
                        label="Valor comisión"
                        prop="vaor_condicion"
                        sortable
                        width="180"
                        >
                            <template slot-scope="scope">
                                <div class="row mx-0 justify-center">
                                    <div class="col-auto px-0 text-general f-16">
                                        {{ convertMoney(scope.row.valor_condicion,scope.idm_moneda) }}
                                    </div>
                                    <i class="icon-start text-warning f-20" />
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column
                        label="Comisión"
                        prop="comision"
                        sortable
                        width="140"
                        >
                            <template slot-scope="scope">
                                <div class="row mx-0 justify-center">
                                    <div class="col-auto px-0 text-general f-16">
                                        {{ scope.row.comision || "-" }}
                                    </div>
                                    <i class="icon-start text-warning f-20" />
                                </div>
                            </template>
                        </el-table-column>
                    </template>
                    <template slot="adicionales-derecha">
                        <el-table-column
                        label="Calificaciones"
                        width="145"
                        >
                            <template slot-scope="scope">
                                <div class="row mx-0 justify-center">
                                    <div class="col-auto px-0 text-general f-16">
                                        {{ scope.row.calificaciones || "-" }}
                                    </div>
                                    <i class="icon-start text-warning f-20" />
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column
                        :label="`Id pedido ${$config.cliente}`"
                        width="180"
                        >
                            <template slot-scope="scope">
                                <div class="row mx-0 justify-center f-16">
                                    {{ scope.row.id_pedido || "-" }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column
                        label="Entrega directa"
                        width="180"
                        >
                            <template slot-scope="scope">
                                <div class="row mx-0 text-general f-16">
                                    {{ scope.row.cliente_entrega_directa ? "Sí":"No" }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column
                        label="Distancia (m)"
                        width="180"
                        >
                            <template slot-scope="scope">
                                <div class="row mx-0 text-general f-16">
                                    {{ scope.row.distancia }} {{ "m" }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column
                        label="Plataforma"
                        width="120"
                        >
                            <template slot-scope="scope">
                                <div class="row mx-0 text-general justify-center f-16">
                                    <i :class="mostrarIcono(scope.row.dispositivo)" class="f-22 text-general" />
                                </div>
                            </template>
                        </el-table-column>
                    </template>
                </tabla-general>
            </div>
            <div class="col-12 bg-white br-b-8 pb-3 d-flex justify-center">
                <div v-if="this.longitud>0" class="bg-general3 text-white br-12 px-2 cr-pointer mt-2" @click="listar_pedidos()">
                    Ver más pedidos
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { Switch } from 'element-ui';
import moment from 'moment'
import Pedidos from "~/services/tiendas/listado_pedidos"
export default {
    data(){
        return {
            datos: [],
        }
    },
    mounted(){
        this.listar_pedidos();
    },
    methods:{
        async listar_pedidos(){
            let id_tienda = this.$route.params.id_tienda
            try {
                let params = {
                    offset: this.datos.length
                }
                const {data} = await Pedidos.listado(id_tienda,params)
                this.datos = [...this.datos, ...data]
                this.longitud = data.length

            } catch (error){
                this.error_catch(error)
            }

        },
        mostrarIcono(dispositivo){
            if(dispositivo == 1){
                return "icon-web"
            }
            if(dispositivo == 2){
                return "icon-android"
            }
            if(dispositivo == 3){
                return "icon-apple"
            }
        },
        estadoNombre(estado){
            estado = parseInt(estado)
            switch (estado){
            case 1:
                return "Sin confirmar"
                break;
            case 2:
                return "Confirmado"
                break;
            case 201:
                return "Alistado"
                break;
            case 202:
                return "Empacado"
                break;
            case 3:
                return "Enviado"
                break;
            case 4:
                return "Finalizado"
                break;
            case 25:
                return "Rechazado"
                break;
            case 26:
                return "Cancelado"
                break;
            case 27:
                return "Cancelado"
                break;
            case 31:
                return "En reclamo"
                break;
            case 32:
                return "Reclamo finalizado"
                break;

            default:
                return "Cambios en proceso"
                break;
            }
        },
        onRowClick(row){
            this.$router.push({ name: 'admin.almacen.pedidos', params: { id_pedido: row.id } })
        },
        pagoEstado(estado){
            estado = parseInt(estado)
            switch (estado){
            case 1:
                return "Contado"
                break;
            case 2:
                return "Pendiente"
                break;
            case 3:
                return "Pagado"
                break;
            case 4:
                return "Anulado"
                break;
            default:
                return "En proceso"
                break;
            }
        },
        formatTime(datetime,fecha){
            datetime = `${datetime} ${fecha}:00`;
            return  moment(datetime).format('hh:mm a')
        },

    }


}
</script>
