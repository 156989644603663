<template>
    <section>
        <div class="row mx-0 border-bottom py-2">
            <div class="d-middle cr-pointer" @click="regresar">
                <i class="icon-left-circle f-20 text-general" />
                <img class="border br-4 obj-cover mx-2" width="36" height="36" :src="tienda.logo_mini_firmado" alt="" />
                <p class="text-general f-600 f-18 mr-4">{{ tienda.propietario_nombre }}</p>
            </div>
            <tabs2 :tabs="tabs" />
            <chatIconoChat :id-tienda="parseInt(this.$route.params.id_tienda)" />
        </div>
        <router-view />
    </section>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    data(){
        return {
            tabsReales: [
                { titulo: 'Información', ruta: 'admin.tiendas.aprobados.informacion' },
                { titulo: 'Clientes', ruta: 'admin.tiendas.aprobados.clientes' },
            ],
        }
    },
    computed: {
        ...mapGetters({
            tienda: 'tiendas/info_cabecera/tienda'
        }),
        tabs(){
            return this.tabsReales
        }
    },
    mounted(){
        this.info_cabecera()
    },
    methods: {
        regresar(){
            this.$router.push({ name: 'admin.tiendas.aprobados' })
        },
        async info_cabecera(){
            await this.$store.dispatch('tiendas/info_cabecera/action_info_cabecera', this.$route.params.id_tienda)
        },
    }
}
</script>

<style lang="css" scoped>
</style>
