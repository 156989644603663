<template>
    <section class="pt-4 px-3 overflow-auto custom-scroll" style="height:calc(100vh - 180px);">
        <p class="f-22 text-general f-600 px-5">
            Actividad del {{ $config.vendedor }}
        </p>
        <div class="row mx-0 align-items-center bg-white br-t-12 px-3 mt-2 py-3">
            <div class="col-12 d-middle">
                <p class="f-500 col-5 pr-0 f-18 text-general">
                    Historial
                </p>
                <div
                v-if="$can('botones_leecheros_activar')"
                v-show="tienda.estado == 6"
                class="btn-general cr-pointer px-3 d-middle-center br-8 bg-general f-15 text-white mx-1 "
                @click="AbrirActivarTienda()"
                >
                    Activar
                </div>
                <div
                v-if="$can('botones_leecheros_activar')"
                v-show="tienda.estado == 5"
                class="btn-general cr-pointer px-3 d-middle-center br-8 bg-general f-15 text-white mx-1"
                @click="AbrirInactivarTienda()"
                >
                    Inactivar
                </div>
            </div>
        </div>
        <div class="row mx-0 bg-white br-b-12">
            <div class="col-7 pr-0">
                <div
                v-for="(h,index3) in historial"
                :key="`key3${index3}`"
                class="row mx-0 br-8 bg-fondo text-general align-items-center justify-content-between my-3"
                style="height:36px;"
                >
                    <div class="col-auto">
                        <p>
                            <strong> {{ h.tipo_nombre }}: </strong> {{ h.created_at | helper-fecha('DD-MMM-Y') }}
                        </p>
                    </div>
                    <div v-if="h.id_motivo" class="col-auto ">
                        <p>
                            <strong class="text-general"> Motivo: </strong> {{ h.motivo }}
                        </p>
                    </div>
                    <div v-if="h.justificacion != null" class="col-auto txt-blue f-600 cr-pointer" @click="AbrirDetallesPostulacion(h)">
                        Ver Detalle
                    </div>
                </div>
            </div>
        </div>
        <modal ref="modalConveniosActivos" titulo="Convenios activos" no-aceptar no-cancelar="true" cerrar="true">
            <div class="text-general justify-center text-center">
                <p> ¡Lo sentimos! No se puede inactivar este {{$config.vendedor}} porque actualmente hace parte de un convenio. </p>
                <p class="mt-4"> Convenios de los que hace parte:</p>
                <p v-for="(con,index) in convenios" :key="index">{{ con.nombre }}</p>
            </div>
        </modal>
        <!-- Partials -->
        <modalDetallePostulacion ref="DetallesPostulacion" :detalle="detalle_historial" />
        <modalInactivarTienda ref="InactivarTienda" />
        <modalPublicarTienda ref="PublicarTienda" />
        <modalActivarTienda ref="ActivarTienda" />
    </section>
</template>

<script>
import { mapGetters } from 'vuex'
import Produccion from "~/services/tiendas/produccion"
export default {
    components: {
        modalDetallePostulacion : () => import('../partials/modalDetallesPostulacion'),
        modalInactivarTienda: () => import('../partials/modalInactivarTienda'),
        modalPublicarTienda: () => import('../partials/modalPublicarTienda'),
        modalActivarTienda: () => import('../partials/modalActivarTienda'),
    },
    data(){
        return {
            historial: [],
            detalle_historial: {},
            convenios: []
        }
    },
    computed:{
        ...mapGetters({
            tienda: 'tiendas/info_cabecera/tienda'
        }),
    },
    mounted(){
        this.historial_estados()
        this.conveniosTienda()
    },
    methods: {
        async conveniosTienda(){
            try {
                const { data } = await Produccion.getConveniosTienda(this.$route.params.id_tienda)
                this.convenios = data.data
            } catch (e){
                this.error_catch(e)
            }
        },
        async historial_estados(){
            try {
                const { data } = await Produccion.historial_estados(this.$route.params.id_tienda)
                this.historial = data.historial
            } catch (error){
                this.error_catch(error)
            }
        },
        AbrirPublicarTienda(){
            this.$refs.PublicarTienda.toggle(this.tienda);
        },
        AbrirInactivarTienda(){
            if(this.convenios.length){
                this.$refs.modalConveniosActivos.toggle()
            }else {
                this.$refs.InactivarTienda.toggle();
            }
        },
        AbrirActivarTienda(){
            this.$refs.ActivarTienda.toggle();
        },
        AbrirDetallesPostulacion(item){
            this.detalle_historial = item
            this.$refs.DetallesPostulacion.toggle();
        },
    }
}
</script>
