<template>
    <section class="custom-scroll overflow-auto" style="height:calc(100vh - 213px);">
        <mapa-google
        ref="GoogleMaps"
        :desactivar-mapa="false"
        icono-central="/img/icons/tendero-point-map.svg"
        :dragable="false"
        height="100%"
        width="100%"
        />
    </section>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
    computed: {
        ...mapGetters({
            puntos: 'produccion/geolocalizacion/puntos',
        })
    },
    watch: {
        puntos(val){
            if(val){
                this.addNewMarkers()
            }
        }
    },
    async created(){
        await this.fetchLeecheros()
    },
    mounted(){
        this.iniciarMapa()
    },
    methods: {
        ...mapActions({
            fetchLeecheros: 'produccion/geolocalizacion/fetchLeecheros',
        }),
        async iniciarMapa(){
            this.$refs.GoogleMaps.initMapMarkerClusterer(this.puntos, true, true)
        },
        addNewMarkers(){
            this.$refs.GoogleMaps.addNewMarkers(this.puntos, true, true)
        },
    }
}
</script>

<style lang="scss" scoped>
.br-12{
    border-radius: 12px;
}
.br-15{
    border-radius: 15px;
}
</style>